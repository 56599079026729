import clientsApi from '../../api/clients';

const state = {
  client: localStorage.customer ? JSON.parse(localStorage.customer) : null,
  vouchers: [],
};

const mutations = {
  SET_CLIENT(state, client) {
    if (client) {
      localStorage.customer = JSON.stringify(client);
      state.client = client;

      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'update-customer',
        value: client,
      }, '*');
    } else {
      localStorage.removeItem('customer');
      state.client = null;
    }
  },
  SET_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers ?? [];
  },
};

const actions = {
  async create(context, data) {
    const res = await clientsApi.create(data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }

    context.commit('auth/SET_TOKEN', res.data.token, { root: true });
    context.commit('SET_CLIENT', res.data.data);

    const iframe = document.getElementById('sso').contentWindow;
    iframe.postMessage({
      action: 'login',
      value: {
        token: res.data.token,
        customer: res.data.data,
      },
    }, '*');

    return res.data.data;
  },
  async update(context, data) {
    const res = await clientsApi.update(context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }

    context.commit('SET_CLIENT', res.data);
    return res.data;
  },
  async get(context, { params }) {
    const res = await clientsApi.find(context.rootState.auth.token, params);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }

    return res?.data;
  },
  async getVoucher(context, { id, params }) {
    if (context.rootState.auth?.token) {
      const res = (await clientsApi.findVoucher(context.rootState.auth.token, id, params));
      if (res?.status === 200) {
        return res?.data;
      } else {
        return null;
      }
    } else {
      return JSON.parse(localStorage.vouchers || '[]')?.find(v => v.id == id);
    }
  },
  async getVouchers(context, params) {
    let vouchers = [];
    if (context.rootState.auth?.token) {
      vouchers = (await clientsApi.findAllVouchers(context.rootState.auth.token, params)).data;
    } else {
      vouchers = JSON.parse(localStorage.vouchers || '[]');
    }
    context.commit('SET_VOUCHERS', vouchers);
    return vouchers;
  },
};

const getters = {
  getName(state) {
    return (state.client ? ((state.client?.lastName || '') + ' ' + state.client?.firstName) : '')?.trim();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
